@use "@angular/material" as mat;

@import 'palette';

@import '~@angular/cdk/overlay-prebuilt.css';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$lts-primary: mat.define-palette($lts-primary);
$lts-accent: mat.define-palette($lts-secondary);
$lts-warn: mat.define-palette(mat.$red-palette, 900);

$lts-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $lts-primary,
      accent: $lts-accent,
      warn: $lts-warn,
    ),
  )
);

@include mat.all-component-themes($lts-app-theme);