/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'variables';
@use 'mixins';

@import './assets/theme/theme';

@font-face {
  font-family: "Antonio";
  //src: local("Antonio"), url(./assets/fonts/antonio/Antonio-Regular.ttf) format("truetype");
  src: local("Anonio"), url(./assets/fonts/Antonio/static/Antonio-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Zilla Slab";
  //src: local("Zilla Slab"), url(./assets/fonts/zilla/ZillaSlab-Bold.ttf) format("truetype");
  src: local("Zilla Slab"), url(./assets/fonts/Zilla_Slab/ZillaSlab-Bold.ttf) format("truetype");
}


/* Search Bar */
.search .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/* Button Toggle: case status */
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0px 22px !important;
  font-size: 1rem;
  line-height: 36px;
}

/* Expansion Panel */
.mat-expansion-panel-body .form-field-flex {
  //width: 100%;
  display: flex;
}
.mat-expansion-panel-body app-dropdown-select,
.mat-expansion-panel-body app-date-range-picker {
  padding: 0 20px;
}
.mat-expansion-panel-body app-dropdown-select:first-of-type,
.mat-expansion-panel-body app-date-range-picker:first-of-type {
    padding-left: 0px;
}
.mat-expansion-panel-body app-dropdown-select:last-of-type,
.mat-expansion-panel-body app-date-range-picker:last-of-type {
  padding-right: 0px;
}

/* Table */
.mat-mdc-table {
  border: 1px solid #D5D5D5 !important;
}
.mat-mdc-table .mat-mdc-header-cell {
  background-color: variables.$light-gray !important;
}
.mat-mdc-table .mat-mdc-header-cell {
  border-bottom: 0 !important;
}

.mdc-data-table__cell {
  border-bottom-style: dashed !important;
}
.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: none !important;
}

.mat-sort-header-content {
  font-size: 15px;
  font-weight: 400;
  color: black;
}

.no-table-data {
  padding: 20px 12px;
  text-align: center;
  font-style: italic;
  font-size: 18px;
}

/* Form Field Subscript Wrapper (Date Range Picker, Dropdown Select) */
.mat-mdc-form-field-subscript-wrapper {
  margin-bottom: 10px
}

/* Tab Group */
.mat-mdc-tab-body-wrapper {
  border-top: 1px solid variables.$light-gray;
  padding: 0 24px;
}

/* Unordered Lists */
ul {
  padding: 0;
  margin-top: 0;

  li {
    list-style: none;
  }
}

*,
html,
body {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: variables.$default-font-family; //  "Roboto", "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: variables.$default-font-size; // 16px or clamp(16px, 1.5vw, 1rem);
  line-height: variables.$default-line-height; // 1.5 rem or clamp(16px, 1.5vw, 1rem);
}

#app-wrapper {
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#app-container {
  flex-grow: 1;
}

.case-details-container,
.manage-users-container {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.125;
  letter-spacing: 0;
}

h1,
.h1 {
  font-size: 2.75rem;
  line-height: normal;
  margin-top: 0.625rem;
  margin-bottom: 0 !important;
  font-family: "Zilla Slab", serif;
  font-weight: 600;
  font-size: clamp(2.3rem, calc(2.3662vw + 1.4127rem), 2.75rem);
}

h1.bold-headline--serif,
h1.headline--serif,
.h1.bold-headline--serif,
.h1.headline--serif {
  font-size: 3rem;
  line-height: normal  ;
  margin-top: 0.625rem;
  margin-bottom: 0 !important;
  font-family: "Zilla Slab", serif;
  font-size: clamp(2.3rem, calc(2.3662vw + 1.4127rem), 3rem);
  font-weight: 700;
}

h2,
.h2 {
  font-size: 1.75rem !important;
  line-height: 1.25 !important;
  font-weight: 500 !important;
  margin-top: 0;
  margin-bottom: 0.325rem;
  //font-family: "Zilla Slab", serif !important;
  font-size: clamp(1.75rem, calc(0.7887vw + 1.6542rem), 1.75rem) !important;
}

h2.bold-headline--serif,
h2.headline--serif,
.h2.bold-headline--serif,
.h2.headline--serif {
  font-size: clamp(1.95rem, calc(1.7465vw + 1.0951rem), 1.75rem) !important;
}

h3,
.h3 {
  color: variables.$medium-dark-gray !important;
  font-size: 1.25rem !important;
  line-height: 1.25 !important;
  margin-top: 0;
  margin-bottom: 0.325rem;
  //font-size: clamp(1.65rem, calc(0.5915vw + 1.4282rem), 1.25rem) !important;
}

h3.bold-headline--serif,
h3.headline--serif,
.h3.bold-headline--serif,
.h3.headline--serif {
  font-size: clamp(1.65rem, calc(1.0986vw + 1.238rem), 1.25rem) !important;
}

h4,
.h4 {
  color: variables.$medium-dark-gray !important;
  font-size: 1rem !important;
  line-height: 1.25 !important;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.45rem, calc(0.338vw + 1.3232rem), 1rem) !important;
}

h4.bold-headline--serif,
h4.headline--serif,
.h4.bold-headline--serif,
.h4.headline--serif {
  font-size: clamp(1.45rem, calc(0.8451vw + 1.1331rem), 1rem) !important;
}

h5,
.h5 {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
  font-size: clamp(1.3rem, calc(0.2254vw + 1.2155rem), 1rem);
}

h5.bold-headline--serif,
h5.headline--serif,
.h5.bold-headline--serif,
.h5.headline--serif {
  font-size: clamp(1.3rem, calc(0.7324vw + 1.0254rem), 1rem);
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.325rem;
}

h6.bold-headline--serif,
h6.headline--serif,
.h6.bold-headline--serif,
.h6.headline--serif {
  font-size: clamp(1.2rem, calc(0.507vw + 1.0099rem), 1rem);
}

.cdk-overlay-connected-position-bounding-box {
  align-items: center;
}

.mat-mdc-progress-spinner {
  margin-top: 45vh;
}

.white-background {
  filter: blur(10rem);
}

.pointer:hover,
.pointer {
  cursor: pointer !important;
}

.blue-text {
  color: variables.$brand-blue;
}

.red-text {
  color: variables.$brand-red;
}

.option-ellipsis {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 10px 16px 10px 16px !important;
}

.table-case-note {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding-top: 18px !important;
}

.mat-column-view {
  padding-right: 0 !important;
}

.mat-column-delete {
  max-width: 120px !important;
}

.classification-value {
  font-size: 12px;
  display: contents;
}

.classification-value__internal {
  color: variables.$lts-gold;
}

.classification-value__external {
  color: variables.$lts-light-blue;
}

.classification-value__litigation {
  color: mat.get-color-from-palette($lts-primary, 300);
}

.classification-value__internal__chip.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: variables.$lts-gold !important;
  opacity: 1 !important;
  .mdc-evolution-chip__text-label {
    color: #000000 !important;
  }
}

.classification-value__external__chip.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: variables.$lts-light-blue !important;
  opacity: 1 !important;
  .mdc-evolution-chip__text-label {
    color: #000000 !important;
  }
}

.classification-value__litigation__chip.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: mat.get-color-from-palette($lts-primary) !important;
  opacity: 1 !important;
  .mdc-evolution-chip__text-label {
    color: #ffffff !important;
  }
}

.status-value__open__chip.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: #ffffff !important;
  border: 1px solid #8C8C8C;
  opacity: 1 !important;
  .mdc-evolution-chip__text-label {
    color: #8C8C8C !important;
  }
}

a  {
  color: variables.$brand-blue;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
}

label,
mat-label,
.btn-group-label {
  font-size: 14px !important;
}

p,
dl,
ol,
ul,
blockquote,
pre,
table {
  font-weight: 300;
}

p,
.content-container {
  line-height: 1.7;
  font-size: variables.$default-font-size;
  margin-top: 0;
  margin-bottom: 1.05rem;
}

p {
  margin-bottom: 0 !important;
}

hr {
  border: 0;
  border-top: 1px solid variables.$light-gray;
}

.element--light-intro {
  font-weight: 300;
  line-height: 1.4;
  font-size: 2.1rem;
  font-size: clamp(1.6rem, calc(1.1268vw + 1.1775rem), 2.1rem);
  margin-top: 0;
}

/* FORMS */
.radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

input {
  caret-color: black !important;
}

.radio-button {
  margin: 5px;
}

// mat-label  {
//   color: black;
// }

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}


///* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//.mat-select-arrow,
//mat-form-field  {
//  color: black;
//  max-width: 100%;
//  line-height: 1.25 !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//label.mat-radio-label  {
//  display: flex;
//  flex-direction: row;
//  justify-content: space-around;
//  padding-bottom: 10px;
//  padding-top: 10px;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//.mat-ripple-element,
//.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
//  background-color: transparent !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
//.mat-radio-outer-circle {
//  border-color: white;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle  {
//  background-color: white;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
//.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
//  color: black;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//.mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
//  color: black;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
//.mat-form-field-flex:hover {
//  color: black;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
//  color: variables.$brand-red;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label  {
//  color: variables.$brand-red !important;
//}
//
//.mat-form-field-invalid .mat-mdc-input-element {
//  caret-color: variables.$brand-red !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
//  color: variables.$brand-red !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-focused .mat-form-field-required-marker {
//  color: variables.$brand-red !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
//  padding: 0.25em 0.5em !important;
//}

// .mat-mdc-form-field-error {
//   color: variables.$brand-red !important;
// }

///* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
//.mat-form-field-subscript-wrapper {
//  font-size: 100% !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
//  background-color: variables.$brand-blue !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
//  background-color: variables.$brand-blue !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-layout  {
//  white-space: inherit !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
//.mat-checkbox-inner-container {
//  margin-top: 3.5px !important;
//  margin-right: 8px !important;
//}

mat-cell {
  align-items: flex-start !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.table-cell-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  //margin-top: 10px;
}

.multiple-line-cell {
  padding-bottom: 10px;
}

.multiple-line-cell:last-child {
  padding-bottom: 0;
}

.mat-calendar-body-selected {
  background-color: variables.$brand-blue;
  color: white;
}

.mat-calandar-body-cell-container button:hover,
.mat-calendar-body-cell-content:hover,
.mat-calendar-body-cell-content:active  {
  background-color: variables.$brand-blue !important;
  color: white;
}

// .mat-mdc-icon-button  {
//   color: variables.$brand-cool-gray !important;
// }

//.mat-icon-button::selection,
//.mat-icon-button:hover,
//.mat-icon-button:active  {
//  color: black !important;
//}

//.mat-tab-body-wrapper {
//  overflow: inherit !important;
//}

/* Global Classes */
.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.padding-none {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.padding {
  padding: 10px .5em !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-left {
  padding-left: .5em !important;
}

.padding-right {
  padding-right: .5em !important;
}

.padding-bottom {
  padding-bottom: 10px !important;
}

/* Margin TOP */
.margin-top {
  margin-top: 40px !important;
}
.margin-sm-top {
  margin-top: 20px !important;
}

/* Margin RIGHT */
.margin-right {
  margin-right: 1.5em !important;
}
.margin-xsm-right {
  margin-right: .5rem !important;
}
.margin-sm-right {
  margin-right: .75em !important;
}

/* Margin LEFT */
.margin-left {
  margin-left: 1.5em !important;
}
.margin-sm-left {
  margin-left: .75em !important;
}

/* Margin BOTTOM */
.margin-bottom {
  margin-bottom: 40px !important;
}
.margin-sm-bottom {
  margin-bottom: 20px !important;
}

/* Margin ALL */
.margin-none {
  margin: 0 !important;
}
.margin {
  margin: 40px 1.5em !important;
}
.margin-sm {
  margin: 20px .75em !important;
}

/* Margin LEFT & RIGHT */
.margin-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.flex-row,
.flex-xs-row,
.flex-sm-row,
.flex-md-row,
.flex-lg-row {
  display: flex !important;
  flex-direction: row !important;
}

.grid-3-25-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25% 25%;
}
.grid-2-25-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25%;
}
.grid-2-50-row {
  display: grid !important;
  grid-template-columns: 50% 50%;
}

.grid-2-25-50-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25%;
}
.grid-col-gap-sm {
  column-gap: 1.5rem;
}

.center,
.flex-center {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.space-between,
.flex-space-between {
  justify-content: space-between !important;
}

.space-around,
.flex-spae-around {
  justify-content: space-around;
}

.space-evenly,
.flex.space-evenly{
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.center-text  {
  text-align: center;
}

.right-text {
  text-align: right;
}

.height-100 {
  height: 100%;
}

.width-xs-10,
.width-sm-10,
.width-md-10,
.lg-width-10 {
  width: 10%;
}

.width-xs-25,
.width-sm-25,
.width-md-25,
.width-lg-25 {
  width: 25%;
}

.width-50,
.width-xs-50,
.width-sm-50,
.width-md-50,
.width-lg-50 {
  width: 50%;
}

.width-xs-75,
.width-sm-75,
.width-md-75,
.width-lg-75 {
  width: 75%;
  max-width: 1400px;
}

.width-100,
.width-xs-100,
.width-sm-100,
.width-md-100,
.width-lg-100  {
  width: 100%;
  max-width: 1400px;
}

.width-auto {
  width: auto;
}

// .content  {
//   max-width: 1400px;
// }

.mobile {
  display: none !important;
}

.display-none {
  display: none;
}

.offscreen {
  position: absolute;
  overflow: hidden;
  left: -9999px;
}

/* Custom Classes */
.required-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px !important;
}

.required {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px !important;
}

// .bottom-nav-buttons {
//   @include mixins.setWidth(100%);
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// }

// .main-nav-buttons {
//   margin-left: 320px;
//   padding-right: 5%;
//   padding-left: 5%;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// }

.primary-button,
.secondary-button,
.accent-button,
.dark-button  {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 32px;
  height: 50px;
  width: 150px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.primary-button {
  background-color: variables.$brand-blue;
  color: white;
}

.secondary-button {
  background-color: variables.$light-gray;
  color: black;
}

.accent-button {
  background-color: variables.$brand-green;
  color: white;
  width: 250px;
}

.dark-button {
  background-color: black;
  color: white;
}

.icon-button  {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

mat-checkbox:focus-within {
  outline: 2px solid variables.$brand-blue;
  outline-offset: 4px;
  border-radius: 2px;
}

mat-radio-button:focus-within {
  outline: 2px solid variables.$brand-blue;
  outline-offset: 4px;
  border-radius: 50px;
}

.mat-mdc-cell:focus-visible,
.mdc-icon-button:focus,
.mdc-button:focus,
.mdc-button:active {
  outline: 2px solid variables.$brand-blue !important;
  outline-offset: 4px;
  border-radius: 2px;
  z-index: 1000 !important;
}

.mat-mdc-radio-button {
  margin: 10px;
}

.institution-heading {
  color: variables.$brand-cool-gray;
  margin-top: 10px;
}

.alert  {
  padding: 20px;
  background-color: variables.$light-blue;
}

.alert i  {
  padding-right: 20px;
  font-size: 24px;
  color: variables.$brand-blue;
}

.alert p {
  margin: 0 !important;
}

.error  {
  color: variables.$brand-red;
  font-weight: bold;
}

/* Small Breakpoint */
@media (max-width: 959.98px) {

  /* Global Classes */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .flex-sm-row {
    display: block !important;
  }

  .margin-sm-right {
    margin-right: 0 !important;
  }
  .margin-sm-left {
    margin-left: 0 !important;
  }
  .margin-sm-top {
    margin-top: 0 !important;
  }

  .width-sm-10 {
    width: 100% !important;
  }
  .width-sm-25 {
    width: 100% !important;
  }
  .width-sm-50 {
    width: 100% !important;
  }
  .width-sm-75 {
    width: 100% !important;
  }
  .width-sm-100  {
    width: 100% !important;
  }

  .content {
    //width: 100%;
    margin: 64px 0 !important;
  }

  .alert i  {
    display: none !important;
  }

  .desktop  {
    display: none !important;
  }

  .required-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
  }
  .required {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .mat-tab-body-wrapper {
    margin-top: 20px !important;
  }

  // .bottom-nav-buttons {
  //   @include mixins.setWidth(100%);
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;
  // }

  // .main-nav-buttons {
  //   margin-left: 0 !important;
  //   padding-right: 0 !important;
  //   padding-left: 0 !important;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-around;
  // }
}

/* Medium Breakpoint*/
@media (max-width: 1279.98px) {
  .content {
    margin: 0 !important;
  }

  .flex-md-row {
    display: block !important;
  }
  .width-md-10 {
    width: 100%;
  }
  .width-md-25 {
    width: 100%;
  }
  .width-md-50 {
    width: 100%;
  }
  .width-md-75 {
    width: 100%;
  }
  .width-md-100 {
    width: 100%;
  }

  .margin-sm-top {
    margin-top: 0 !important;
  }

  .mat-expansion-panel-body app-dropdown-select,
  .mat-expansion-panel-body app-date-range-picker {
    padding: 0 !important;
  }
}

/* Large Breakpoint*/
@media (max-width: 1279.98px) {
  .flex-lg-row {
    display: block !important;
  }
  .width-lg-10 {
    width: 100%;
  }
  .width-lg-25 {
    width: 100%;
  }
  .width-lg-50 {
    width: 100%;
  }
  .width-lg-75 {
    width: 100%;
  }
  .width-lg-100 {
    width: 100%;
  }

  .margin-sm-top {
    margin-top: 0 !important;
  }
}

/* Medium Breakpoint*/
@media (min-width: 960px) and (max-width: 1279.98px) {

}

/* Less Than Large Breakpoint*/
@media (max-width: 1279.98px) {

}

/* Greater Than Small Breakpoint*/
@media (min-width: 960px) {

}

/* Greater Than X-Small Breakpoint*/
@media (min-width: 600px) {

}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

code {
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  font-weight: bold;
}
