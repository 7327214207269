$hawkeye-gold: #ffcd00;

/* UI approved colors: use with white text */
$brand-cool-gray: #63666a;
$brand-blue: #00558c;
$brand-green: #00664f;
$brand-red: #bd472a;
/* end of UI approved colors */

/* LTS Colors */
$lts-gold: #CAA00A;
$lts-light-blue: #71BAD9;

/* CAF colors */
$dark-color: black;
$dark-gray: #333333;
$medium-dark-gray: #63666A;
$light-gray: #DFDFDF;
$light-blue: #E3F2FD;
$light-gray: #E6E5E5;
$lighter-gray: #F2F2F2;
$light-color: #F7F7F7;

/* font defaults */
$default-font-size: 14px;
$default-font-family: "Roboto", "Helvetica Neue", helvetica, arial, sans-serif;
$default-line-height: 1.5rem;

/** shared proportions **/
$column-width: 5%;
$border-radius: 5px;
$default-spacing: 10px;

/** breakpoints **/
$mobile-max: 959px;
$desktop-min: 959.1px;
